import React, {useState, useEffect} from "react"
// components
import Navbar from "../components/navbar.js";
import Hero from "../components/hero.js";
import View from "../components/view.js";
import EmissionCounter from "../components/emissionCounter.js";
import Footer from "../components/footer";
import Sidebar from "../components/sidebar";
import Modal from "../components/modal";
import VideoSection from "../components/videoSection";
// Data
import Data_hero from "../data/Hero.yaml";
import Data_view1 from "../data/View1.yaml";
import Data_view4 from "../data/ReducedEmissionsView.yaml";
import Data_contact from "../data/View_Contact.yaml";
import Data_footer from "../data/Footer.yaml";
// Images
import Logo_ecoAD from "../../static/images/ecoad-Logo.svg";
import Logo_ecoAD_Footer from "../../static/images/eco-ad-signet-weiss.svg";
import Siegel_ecoAD from "../../static/images/mockup_ecoad_screens.jpg";
import Logo_iq from "../../static/images/iq_black.svg";
import Image_hero from "../../static/images/undraw_online_ad_re_ol62.svg";
import Image_view1 from "../../static/images/undraw_heartbroken_cble.svg";
import Image_view4 from "../../static/images/undraw_the_world_is_mine_nb0e.svg";
import Image_view5 from "../../static/images/undraw_mindfulness_scgo.svg";
import Image_contact from "../../static/images/undraw_contact_us_15o2.svg";
import Button from "../components/button.js";
import CarbonString from "../components/carbonString.js";

const Home = () => {
    const [sidebarActive, setSidebarActive] = useState(false);
    const [infoModalActive, setInfoModalActive] = useState(false);
    const [scrollbarWidth, setScrollbarWidth] = useState("15px");
	const [emissionInKg, setEmissionInKg] = useState(0);
    const onHamburgerClickHandler = (e) =>{
        setSidebarActive(true);
    }
    const onSidebarCloseClickHandler = (e) => {
        setSidebarActive(false);
    }
	const fetchEmissionsData = () => {
		let xhr = new XMLHttpRequest();
		xhr.addEventListener("load", () =>{
			setEmissionInKg(Number.parseInt(JSON.parse(xhr.responseText)[0]));
		});
		xhr.open("GET", "https://ecoad.solutions/impressionData/savedData.json");
		xhr.send();
	}
    useEffect(() => {
        if(sidebarActive || infoModalActive){
            document.body.style.overflow = "hidden";
            document.body.style.paddingRight = scrollbarWidth
            document.querySelector("#navbar").style.paddingRight = scrollbarWidth;
        }
        else{
            setTimeout(() =>{
                document.body.style.overflow = "auto";
                document.body.style.overflowX = "hidden";
                document.body.style.paddingRight = "inherit";
                document.querySelector("#navbar").style.paddingRight = "inherit";
            },300)
        }
    }, [sidebarActive, infoModalActive])
    useEffect(() => {
        let width = window.innerWidth - document.documentElement.clientWidth + "px";
        document.body.style.overflowX = "hidden";
        setScrollbarWidth(width);

        Array.from(document.querySelectorAll('a[href^="#"]')).forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                setSidebarActive(false);
                document.querySelector(this.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth'
                });
            });
        });
		fetchEmissionsData();
    },[])
    return(
        <div style={(sidebarActive || infoModalActive) ? {overflow: "hidden"} : {}} className="relative mx-auto">
            <Sidebar
                active={sidebarActive}
                onCloseClick={onSidebarCloseClickHandler}
                lists={Data_footer.lists}
            />
            <Modal
                active={infoModalActive}
                onBackgroundClick={(e) => setInfoModalActive(false)}
            />
            <Navbar
                logo_ecoAD={Logo_ecoAD}
                logo_iq={Logo_iq}
                onHamburgerClick={onHamburgerClickHandler}
                sidebarActive={sidebarActive}
                padding={scrollbarWidth}
            />
            <main>
                <Hero
                    image={Image_hero}
                    imageLeft={false}
                    data={Data_hero}
                />
                {/*<EmissionCounter
                    image={Image_view4}
                    content={Data_view4.content}
					kg={emissionInKg}
                    imageRight={true}
                    onInfoIconClick={(e) => setInfoModalActive(true)}
                />*/}
                 {/*<VideoSection />*/}
                <View
                    image={Image_view1}
                    title={Data_view1.title}
                    header={Data_view1.header}
                    content={Data_view1.content}
                    imageRight={false}
                />
                <View
                    image={Siegel_ecoAD}
                    title={"reduce"}
                    header={"%%eco ads®%% reduzieren die emittierte Menge CO2 pro Werbeausspielung."}
                    imageRight={true}
                >
                    <p className="text-lg">
                        <CarbonString text={"Wir haben an allen Ecken und Enden optimiert und signifikante CO2-Einsparungen erreicht:"}/>
                    </p>
                    <ul className="ml-5 mt-5 list-disc">
                        <li className="my-2"><span className="font-bold">eco ads® </span>haben ein deutlich geringeres Dateigewicht - je nach Werbemittel bis zu 90 % weniger.</li>
                        <li className="my-2"><span className="font-bold">eco ads® </span>sind auf ein notwendiges Maß an Trackings reduziert. Beim Tracking eines Werbemittels wird mithilfe spezieller Technologien verfolgt, wie viele Personen u.a. die Werbung gesehen oder angeklickt haben. Bei unseren eco ads® wird nur das gemessen, was absolut essenziell ist. Wir vermeiden lange Skripte, reduzieren Daten und sparen Energie.</li>
                    </ul>
                    <p>
                        <b>eco ads®</b> sind eingebettet in ein CO2-optimiertes technologisches Setup.
                    </p>
                    <p>
                        iq digital setzt sich dabei nicht nur für den Klimaschutz ein, sondern hat darüber hinaus auch stets soziale und gesellschaftliche Aspekte im Blick. Dafür haben wir im August 2024 von Eco Vadis die Bronzemedaille verliehen bekommen.
                    </p>
                </View>
                <View
                    image={Image_view5}
                    title={"why"}
                    header={"Was du tun kannst, um Nachhaltigkeit in der Digitalwerbung zu unterstützen?"}
                    content={"Jedes Mal, wenn Sie eine digitale Werbung sehen, die das %%eco ad®%% Label trägt, können Sie sich sicher sein: Das werbende Unternehmen übernimmt ökologische Verantwortung! \n Sie wollen sich auch selbst stärker einbringen? Setzen Sie auf digitale Medien, die qualitativ hochwertigen Content anbieten. Diese erkennen Sie daran, dass sie nicht mit Werbung überladen sind und eine entsprechende journalistische Qualität aufweisen."}
                    imageRight={false}
                />
                {/* <Break content={Data_break_partner.content} id="break_partner">
                    <div className="max-w-screen-xl flex flex-wrap justify-center items-center content-around mx-auto mt-10">
                        {Data_break4.list.map((element, index) => {
                            return(
                                <div key={index} className="w-80 h-20 bg-white m-4 flex justify-center items-center">
                                    <div>
                                        <img className="max-w-20 max-h-16" src={"./images/" + element.filename} alt={element.alttext}></img>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Break> */}
            </main>
            <View
                image={Image_contact}
                title={Data_contact.title}
                header={Data_contact.header}
                content={Data_contact.content}
                imageRight={true}
            >
                <div className="xl:flex xl:justify-around xl:items-center" style={{alignItems:"flex-start",flexDirection:"column"}}>
                    <div className="my-3 full-size">
                        <Button
                            text={"Kontakt"}
                            link={"https://www.iqdigital.de/service/kontakt/iq-digital/"}
                            target={"_blank"}
                            className="my-3"
                        />
                    </div>
                    <div className="my-3 full-size">
                        <Button
                          text={"Mehr Informationen"}
                          link={"/files/eco_ad_by_iq_digital.pdf"} // Correct path to PDF
                          target={"_blank"}
                          className="my-3"
                        />
                    </div>
                    <div className="my-3 full-size" >
                        <Button
                            text={"Werbemittel-Showroom"}
                            link={"https://www.iqdigital.de/Produkte/Digital/werbemittel-showroom"}
                            target={"_blank"}
                            className="my-3"
                        />
                    </div>
                </div>
            </View>
            <Footer
                data={Data_footer}
            />
        </div>
    )
}

export default Home;
